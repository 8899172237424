//@ts-nocheck
import { message } from "antd";
import axios from "axios";

import { ESTIMATE_STATES_IDS } from "../../../../../pages/Handler/ui/ProHandler/constants";
import { IReplacementInExpenditure } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/ReplacedExpenditure/ReplacedExpenditure";
import { IExpenditureInHandlerProduction } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/types";

import { ExpenditureType } from "types/enums/ExpenditureTypeEnum";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import { getExpendituresUrl } from "./utils/getExpendituresUrl";
import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { queryParamsFormatter } from "utils/formatters/queryParamsFormatter";

const ESTIMATE_ITEMS_LIMIT = 500;

export function apiLoadBuildingSections(buildingId) {
  return axios
    .get(`/building/${buildingId}/sections/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
    })
    .then(
      ({ data }) => data,
      (error) => {
        console.error(error);
      }
    )
    .catch((e) => console.error(e));
}

export const apiLoadSections = (() => {
  let cancel;
  return (buildingId, estimateStateId, params) => {
    if (cancel) cancel();

    let url = `/building/${buildingId}/estimate/${estimateStateId}/sections/`;
    if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) url = `/building/${buildingId}/estimate/sections/`;
    return axios
      .get(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT, ...params },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  };
})();

export function apiLoadSection(buildingId, sectionId, estimateStateId, isForTree = false) {
  let url = `/building/${buildingId}/estimate/${estimateStateId}/sections/${sectionId}/`;
  if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) url = `/building/${buildingId}/estimate/items/${sectionId}/`;
  return axios
    .get(url, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
    })
    .then(async ({ data }) => {
      if (estimateStateId === ESTIMATE_STATES_IDS.DRAFT && isForTree) {
        data["subsections"] = await axios
          .get(`/building/${buildingId}/estimate/draft/sections/`, { params: { parent: sectionId } })
          .then(({ data }) => data?.results || []);
      }
      return data;
    })
    .catch(errorCatcher);
}

export const addMaterial = (buildingId, data) => {
  return axios.post(`/building/${buildingId}/materials/out-of-estimate/`, data).catch(errorCatcher);
};

export const addAdditionalMaterial = (buildingId, data) => {
  return axios.post(`/building/${buildingId}/materials/additional/`, data).catch(errorCatcher);
};

export const changeEstimateItemsStateRequest = (buildingId, payload) => {
  if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) payload.from_state = "";
  return axios.post(`/building/${buildingId}/estimate/change_state/`, payload);
};

export const changeEstimateItemsStateToProductionRequest = (buildingId, payload) => {
  return axios.post(`/building/${buildingId}/estimate/items/confirm/`, payload);
};

export const getSections = (buildingId, config) => {
  return axios
    .get(`/building/${buildingId}/estimate/items/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
      ...config,
    })
    .then((res) => {
      return res?.data;
    })
    .catch(errorCatcher);
};

export const getLiteEstimateSectionsTree = (
  buildingId: string,
  onlyEstimate: number = 1
) /* : Promise<SectionForRelations>  */ => {
  return (
    axios
      .get(/* <SectionForRelations> */ `/tree/buildings/${buildingId}/lite/?only_estimate=${onlyEstimate}`)
      .then((res) => {
        //это здесь не нужно
        return res?.data;
      })
      //это здесь не нужно
      .catch(errorCatcher)
  );
};

export function apiLoadExpenditures(buildingId, sectionId, config = {}, type = "draft") {
  return axios
    .get(`/building/${buildingId}/estimate/${type}/sections/${sectionId}/expenditures/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
      ...config,
    })
    .then(
      ({ data }) => data,
      (error) => {
        console.error(error);
      }
    )
    .catch(errorCatcher);
}

export function apiLoadTypeExpenditures(buildingId, params, estimateStateId = ESTIMATE_STATES_IDS.DRAFT, sectionId) {
  let url = `/building/${buildingId}/estimate/${estimateStateId}/expenditures/`;
  if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) url = `/building/${buildingId}/estimate/expenditures/`;
  if (estimateStateId === ESTIMATE_STATES_IDS.DRAFT)
    url = `/building/${buildingId}/estimate/draft/sections/${sectionId}/expenditures/`;
  return axios
    .get(url, {
      params: queryParamsFormatter(params, { limit: ESTIMATE_ITEMS_LIMIT }),
    })
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiCreateExpenditure(buildingId, sectionId, expenditure, type = "draft") {
  return axios
    .post(`/building/${buildingId}/estimate/${type}/sections/${sectionId}/expenditures/`, expenditure)
    .then(({ data }) => {
      message.success("Расценка успешно создана");
      return data;
    })
    .catch(errorCatcher);
}

export const changeEstimateItemDiscountRequest = (buildingId, estimateItemId, discount) => {
  return axios.post(`/building/${buildingId}/estimateitem/${estimateItemId}/set_discount/`, {
    discount_cost: discount,
  });
};

export const changeEstimateItemCostRequest = (buildingId, expenditureId, cost) => {
  return axios.post(`/building/${buildingId}/estimateitem/${expenditureId}/set_cost/`, { estimated_cost: cost });
};

export const changeExpenditureRequest = (ids, changedExpenditure, isDefaultExpenditure) => {
  return axios.patch(`${getExpendituresUrl(ids, isDefaultExpenditure)}${changedExpenditure.id}/`, changedExpenditure);
};

export function deleteExpenditureRequest(ids) {
  const expendituresUrl = getExpendituresUrl(ids);
  return axios.delete(`${expendituresUrl}${ids.expenditure}/`);
}

export const changeEstimateItemStatusRequest = (buildingId, payload) => {
  if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) payload.from_state = "";
  return axios.post(`/building/${buildingId}/estimate/change_status/`, payload);
};

export const changeSectionRequest = (buildingId, estimateStateId, changedSection) =>
  axios.patch(
    `/building/${buildingId}/estimate/${!estimateStateId ? "draft" : estimateStateId}/sections/${changedSection.id}/`,
    changedSection
  );

export const getExpenditureSubMaterialsRequest = (buildingId, expenditureId) =>
  axios.get(`/building/${buildingId}/relates/expenditures/${expenditureId}/`);

export const getExpendituresFueRequest = (name) => axios.get(`/help/fue/expenditures/`, { params: { name } });

export const swapExpendituresRequest = (buildingId, old, candidate, successCallback) =>
  axios
    .post(`/building/${buildingId}/estimate/swap_expenditures/`, { old, new: candidate })
    .then(() => {
      successCallback?.();
      message.success("Позиция заменена");
    })
    .catch(errorCatcher);

export const getReplacementExpenditures = (buildingId, expenditureId) =>
  axios.get(`/building/${buildingId}/estimate/replacement_expenditures/${expenditureId}/`).catch(errorCatcher);

export const replaceExpenditureDirectlyRequest = (buildingId, expenditureId, replacementId, successCallback) =>
  axios
    .post(`/building/${buildingId}/estimate/replacement_expenditures/${expenditureId}/`, { replace_id: replacementId })
    .then(() => {
      successCallback?.();
      message.success("Позиция заменена");
    })
    .catch(errorCatcher);

export const uploadReplacementFile = (data: Record<any, any>, buildingId: number, replacementId: number) => {
  return axios.patch<IReplacementInExpenditure>(
    `/building/${buildingId}/estimate/replacements/${replacementId}/`,
    data
  );
};

export const sectionsApi = {
  deleteDefaultItem: (buildingId: string, id: number) => {
    return axios.delete(`/building/${buildingId}/estimateitem/${id}/delete_default/`);
  },
  deleteListDefaultItems: (buildingId: number, ids: number[]) => {
    return axios.post(`/building/${buildingId}/estimate/expenditures/default/list/delete/`, {
      expenditures: ids,
    });
  },
  addNewListDefaultItems: (buildingId: number, list: IExpenditureInHandlerProduction["editingForm"][]) => {
    return axios.post(`/building/${buildingId}/estimate/expenditures/default/list/new/`, { expenditures: list });
  },
  editDefaultItemInList: (
    buildingId: number,
    expenditureInfo: Partial<IExpenditureInHandlerProduction["editingForm"]>
  ) => {
    return axios.patch(
      `/building/${buildingId}/estimate/expenditures/default/new/${expenditureInfo?.id}/`,
      expenditureInfo
    );
  },
};

export type CountExpendetures = {
  all: 0;
  work: 0;
  material: 0;
  machine: 0;
  equipment: 0;
  transport: 0;
  groups: 0;
  sections: 0;
  subsections: 0;
};

export type SectionForRelations = {
  id: number;
  name: string;
  count_expenditures: CountExpendetures;
  sections: {
    id: number;
    name: string;
    count_expenditures: CountExpendetures;
    subsections: {
      id: number;
      name: string;
      count_expenditures: CountExpendetures;
      expenditures: {
        id: number;
        name: string;
        expenditure_type: ExpenditureType;
        number: string;
        count: string;
        measure: string;
      }[];
      groups: [];
    }[];
  }[];
};
