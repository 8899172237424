import { memoize } from "lodash";
import React, { FC, useMemo } from "react";

import HiddenSubsection from "components/UI/_TODO/SectionsWithCheckedExpenditures/components/HiddenSubsection/HiddenSubsection";
import HideBlock from "components/UI/atoms/_TODO/HideBlock";

import { IEstimateTargetWithType } from "../SpecificationAddRelationsModal";

import ISection from "types/interfaces/Section";

import { IStructWithRecursiveChildren } from "../hooks/useSpecificationRelationsData";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./SpecificationRelationsSections.module.scss";

export interface ISectionsWithCheckedExpendituresProps {
  sections: IStructWithRecursiveChildren[];
  opened: boolean;
  selectedEstimateTarget: IEstimateTargetWithType | null;
  selectedRdTarget: IEstimateTargetWithType[];
  onCheckExpenditure: any; //(target: IEstimateTarget, isChecked: boolean) => void;
  isDisabled?: boolean;
  tab: string;
}

const SpecificationRelationsSections: FC<ISectionsWithCheckedExpendituresProps> = ({
  sections,
  opened,
  selectedEstimateTarget,
  onCheckExpenditure,
  //
  tab,
  selectedRdTarget,
}) => {
  const checkedExpenditures = useMemo(() => {
    if (tab === "rd") {
      return selectedRdTarget.reduce<Record<string, boolean>>((acc, cur) => {
        return { ...acc, [cur.expenditureId!]: true };
      }, {});
    }
    return selectedEstimateTarget?.expenditureId ? { [selectedEstimateTarget.expenditureId]: true } : {};
  }, [selectedEstimateTarget, tab, selectedRdTarget]);

  const partialCheckExpenditure =
    (sectionId: ISection["id"]) => (estimateTarget: Omit<IEstimateTargetWithType, "sectionId">, isChecked: boolean) => {
      onCheckExpenditure(
        {
          ...estimateTarget,
          sectionId,
        },
        isChecked
      );
    };

  const memoizedPartialCheckExpenditure = useMemo(
    () => memoize(partialCheckExpenditure, stringifyArgs),
    [onCheckExpenditure]
  );

  //TODO сделать рекурсивно для любой вложенности секций
  return (
    <div>
      {sections?.map((section) => (
        <div key={section.id} className={styles.section}>
          <HideBlock
            titleClassName={styles.sectionTitleClassName}
            title={section.name}
            isHiddenDefault={
              !(
                selectedEstimateTarget?.sectionId === section?.id ||
                opened ||
                section.children?.some((s) => s.children?.some((el) => checkedExpenditures[el.id])) ||
                sections?.length === 1
              )
            }
          >
            <div className={styles.subSection}>
              {Array.isArray(section.children) &&
                section.children.map((subsection) => (
                  <HiddenSubsection
                    subsection={subsection}
                    opened={
                      opened ||
                      subsection.children?.some((el) => checkedExpenditures[el.id]) ||
                      section.children?.length === 1
                    }
                    expenditures={subsection.children}
                    expendituresAreLoading={false}
                    selectedEstimateTarget={selectedEstimateTarget}
                    checkedExpenditures={checkedExpenditures}
                    onCheckExpenditure={memoizedPartialCheckExpenditure(section.id)}
                    key={subsection.id}
                    onOpenHiddenSubsection={() => {}}
                    showCount
                  />
                ))}
            </div>
          </HideBlock>
        </div>
      ))}
    </div>
  );
};

export default React.memo(SpecificationRelationsSections);
