import React, { useCallback } from "react";

import Checkbox from "../../../../../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";

import styles from "../../HiddenSubsection.module.scss";

const CheckedExpenditure = ({ isChecked, onCheck, expenditure, showCount }) => {
  const handleOnCheck = useCallback(
    (e) => onCheck(expenditure.id, e.target.checked, expenditure),
    [onCheck, expenditure.id]
  );

  return (
    <div className={styles.row}>
      <Checkbox className={styles.checkbox} checked={isChecked} onChange={handleOnCheck}>
        {expenditure.name}
      </Checkbox>
      {showCount && (
        <span>
          {expenditure.count} {expenditure.measure}
        </span>
      )}
    </div>
  );
};

export default React.memo(CheckedExpenditure);
