import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { handlerIndexesInvalidateKeySelector } from "../../model/selectors";
import { editHandlerIndex } from "../../model/thunks";
import HandlerIndexForm from "../HandlerIndexForm/HandlerIndexForm";
import Actions from "shared/ui/controls/Actions/Actions";

import { ICreateIndex, IHandlerIndex } from "../../model/types";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./HandlerIndexesModal.module.scss";

interface IProps {
  values: IHandlerIndex;
  onRemove: (id: number) => void;
  isPending: boolean;
}

const HandlerIndexesRow: React.FC<IProps> = ({ values, onRemove, isPending }) => {
  const objectId = useObjectId(2);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const invalidateKey = useSelector(handlerIndexesInvalidateKeySelector);

  const assigned = useMemo(() => {
    const arr = [values.is_mtr && "МТР", values.is_smr && "СМР"].filter((el) => !!el);

    return arr.join(", ");
  }, [values]);

  useEffect(() => {
    setIsEdit(false);
  }, [invalidateKey]);

  const onEdit = (vals: Partial<ICreateIndex>) => {
    dispatch(editHandlerIndex(objectId, values.id, vals));
  };

  if (isEdit) {
    return (
      <HandlerIndexForm onSubmit={onEdit} values={values} onCancel={() => setIsEdit(false)} isPending={isPending} />
    );
  }

  return (
    <div className={classNames(styles.cols, styles.row)}>
      <div className={styles.nameWrapper}>
        <div className={styles.name} title={values.title}>
          {values.title}
        </div>
      </div>
      <div className={styles.index}>{values.value}</div>
      <div className={styles.centered}>{assigned}</div>
      <div className={styles.name} title={values.reason}>
        {values.reason}
      </div>
      <div>
        <div className={classNames(styles.createdAt)}>
          <div className={styles.author}>{sliceTextByConstraint(values.author.name, 15)}</div>
          <div className={styles.day}>
            <div className={styles.date}>{moment(values.created_at).format("DD.MM.YY")}</div>
            <div className={styles.time}>в {moment(values.created_at).format("HH:mm")}</div>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        {
          <Actions
            disabled={isPending}
            canEdit={false}
            onEdit={() => setIsEdit(true)}
            canRemove
            onRemove={() => onRemove(values.id)}
          />
        }
      </div>
    </div>
  );
};

export default HandlerIndexesRow;
