import axios from "axios";

import { ICreateCoefficient, IHandlerCoefficient } from "./types";
import { IListResponseData } from "types/ListResponse";

export const coefficientsApi = {
  getcoefficients: (buildingId: string, section_id: number) => {
    return axios.get<IListResponseData<IHandlerCoefficient>>(`/building/${buildingId}/estimate/coefficients/`, {
      params: { section_id, limit: 500 },
    });
  },
  createCoefficient: (buildingId: string, vals: ICreateCoefficient) => {
    return axios.post(`/building/${buildingId}/estimate/coefficients/`, vals);
  },
  editCoefficient: (buildingId: string, indexId: number, vals: Partial<ICreateCoefficient>) => {
    return axios.patch(`/building/${buildingId}/estimate/coefficients/${indexId}/`, vals);
  },
  deleteCoefficient: (buildingId: string, indexId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/coefficients/${indexId}/`);
  },
};
