import React, { FC } from "react";

import ChartPopup from "components/pages/Building/components/ChartPopup/ChartPopup";
import ProgressBar from "components/pages/Building/components/ProgressBar/ProgressBar";
import { HandlerIndexes } from "pages/Handler/ui/ProHandler/widgets/HandlerIndexes";

import Amount from "shared/ui/dataDisplay/Amount/Amount";

import ISection from "types/interfaces/Section";

import { percentsFormatter } from "utils/percentsFormatter";

import sectionStyles from "../BuildingSection.module.scss";
import styles from "./Amounts.module.scss";
import MultipleButton from "shared/ui/controls/MultipleButton/MultipleButton";
import { HandlerCoefficients } from "pages/Handler/ui/ProHandler/widgets/HandlerCoefficients";

export enum AMOUNTS_VARIANTS {
  INLINE,
  HIDDEN,
}

export type AmountsType = Record<"all" | "completed" | "accepted" | "invested" | "completed_percent", number> & {
  variant: AMOUNTS_VARIANTS;
  measure?: 1 | 1000 | 1000000;
};

export interface IAmountsProps {
  amounts: AmountsType;
  isWithIndexes?: boolean;
  section?: ISection;
}

const Amounts: FC<IAmountsProps> = ({ amounts, isWithIndexes = false, section = {} }) => {
  if (amounts.variant === AMOUNTS_VARIANTS.HIDDEN) {
    return (
      <>
        <ChartPopup
          containerClassName={sectionStyles.infoContainer}
          type="currency"
          all={amounts.all}
          completed={amounts.completed}
          accepted={amounts.accepted}
          popupClassName={undefined}
          onHover={undefined}
          isLast={undefined}
          isWithIndexes={isWithIndexes}
          customLabels={undefined}
        />
        {isWithIndexes && (
          <MultipleButton border>
            <HandlerIndexes section={section as any} amounts={amounts as any} className={sectionStyles.index} />
            <HandlerCoefficients section={section as any} amounts={amounts as any} className={sectionStyles.index} />
          </MultipleButton>
        )}
        <ProgressBar
          className={sectionStyles.progressBar}
          fillerClassName={sectionStyles.progressBarFiller}
          completed={amounts.completed_percent}
          displayValue={undefined}
        />
      </>
    );
  }

  if (amounts.variant === AMOUNTS_VARIANTS.INLINE) {
    const completedPercent = (amounts.completed * 100) / amounts.all;
    const investedPercent = (amounts.invested * 100) / amounts.all;
    const acceptedPercent = (amounts.accepted * 100) / amounts.all;

    return (
      <div className={styles.amounts}>
        <Amount
          titleClassName={sectionStyles.budgetTitle}
          title={`Израсходовано (${percentsFormatter(investedPercent)})`}
          value={amounts.invested}
          isBold={false}
          className={styles.spent}
          measure={amounts.measure}
        />
        <Amount
          titleClassName={sectionStyles.budgetTitle}
          title={`Выполнено (${percentsFormatter(completedPercent)})`}
          value={amounts.completed}
          isBold={false}
          className={undefined}
          measure={amounts.measure}
        />
        <Amount
          titleClassName={sectionStyles.budgetTitle}
          title={`Принято (${percentsFormatter(acceptedPercent)})`}
          value={amounts.accepted}
          isBold={false}
          className={undefined}
          measure={amounts.measure}
        />
      </div>
    );
  }

  return null;
};

export default React.memo(Amounts);
