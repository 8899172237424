import cn from "classnames";
import React from "react";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./index.module.scss";

interface Iprops {
  name?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  decimalPlace?: number;
  placeholder?: string;
  label?: string;
  className?: string;
  hideControls?: boolean;
  containerClassName?: string;
}

const InputNumber = ({ name, value, setValue, decimalPlace, placeholder, label, className, hideControls, containerClassName }: Iprops) => {
  const toFixed = decimalPlace !== undefined ? +decimalPlace : 2;
  const handleChange = (value: string) => {
    const numberValue = Number.parseFloat(value);
    const re = new RegExp(`^[0-9]*(\.)?[0-9]{0,${toFixed}}$`, "g");

    if (re.test(value.toString())) {
      setValue(numberValue >= 0 ? value?.replace(",", ".") : "");
    }
  };

  const handlePlus = (value: string) => {
    const val = Number.parseFloat(value);
    setValue(String(Math.round(val * 100 + 100) / 100 || 1));
  };

  const handleMinus = (value: string) => {
    const val = Number.parseFloat(value) || 0;
    const valToFixed = val.toFixed(toFixed);
    setValue(String(+valToFixed - 1 > 0 ? Math.round(val * 100 - 100) / 100 : 0));
  };

  const formattedValue = [undefined, null].includes(value as any) ? "" : value;

  return (
    <div className={cn("inputContainer", containerClassName)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={cn(styles.container, className)}>
        <input
          name={name}
          className={styles.input}
          value={formattedValue}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
        />
        {!hideControls && (
          <div className={styles.actionBlock}>
            <div className={styles.arrow} onClick={() => handlePlus(value)}>
              <ArrowSmallUpGrey />
            </div>
            <div className={styles.arrow} onClick={() => handleMinus(value)}>
              <ArrowSmallUpGrey rotate={"180"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputNumber;
